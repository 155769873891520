import React from 'react';

import Section from './Section';
import Home from './Sections/Home';
import Services from './Sections/Services';
import Team from './Sections/Team';
import About from './Sections/About';
import Customers from './Sections/Customers';
import ContactUs from './Sections/ContactUs';


const Main: React.FC = () => {
  return (
    <>
      <Section id="home" childComponent={ <Home /> } />
      <Section id="services" childComponent={ <Services /> } />
      <Section id="team" childComponent={ <Team /> } />
      <Section id="about" childComponent={ <About /> } />
      <Section id="customers" childComponent={ <Customers /> } />
      <Section id="contact-us" childComponent={ <ContactUs /> } />
    </>
  );
};

export default Main;
