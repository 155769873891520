import React, { useState } from 'react';
import { addDoc, collection, Timestamp } from 'firebase/firestore';

import { db } from '../../Firebase';

const BlogCreate: React.FC = () => {
  const [title, setTitle] = useState<string>('');
  const [body, setBody] = useState<string>('');

  const Blogs = collection(db, 'blogs');

  async function addBlog(e: any) {
    e.preventDefault();

    try {
      const docRef = await addDoc(Blogs, {
        blog: {
          title: title,
          body: body,
          publish: false,
          published_on: Timestamp.fromDate(new Date()),
        },
      });
      console.log('Document written with ID: ', docRef.id);
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  }

  return (
    <div>
      <form onSubmit={ (event) => {
        addBlog(event);
      } }>
        <input type="text" placeholder="Title"
               onChange={ (e) => {
                 setTitle(e.target.value);
               } } required />

        <textarea name="content"
                  placeholder="write yoyr content here"
                  rows={ 10 }
                  cols={ 150 }
                  onChange={ (e) => {
                    setBody(e.target.value);
                  } }
                  required
        ></textarea>

        <button type="submit">Submit</button>
      </form>
    </div>

  );
};

export default BlogCreate;
