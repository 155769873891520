import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Transition } from '@headlessui/react';

import bgFlag from '../assets/bg.svg';
import enFlag from '../assets/en.svg';

interface Language {
  name: string;
  code: string;
  icon: string;
}

const LanguageSwitcher: React.FC = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const languages: Language[] = [
    {
      name: t('languageSwitcher.languages.bulgarian'),
      code: 'bg',
      icon: bgFlag
    },
    {
      name: t('languageSwitcher.languages.english'),
      code: 'en',
      icon: enFlag
    }];

  const selectedLanguage = getLanguage(i18n.language);

  async function handleChangeLanguage(languageCode: string) {
    await i18n.changeLanguage(languageCode);
  }

  function getLanguage(languageCode: string) {
    return languages.find((language) => language.code === languageCode);
  }

  return (
    <div className="relative inline-block text-left">
      <Menu as="div" className="relative ml-3">
        <div>
          <Menu.Button
            className="relative flex rounded-full bg-white text-sm">
            <span className="absolute -inset-1.5" />
            <span className="sr-only">{ t('languageSwitcher.altText') }</span>
            <img
              className="h-8 w-8 rounded-full"
              src={ selectedLanguage!.icon }
              alt={ selectedLanguage!.name }
            />
          </Menu.Button>
        </div>
        <Transition
          as={ Fragment }
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            { languages.filter(language => language.code !== i18n.language).map((language) => (
              <Menu.Item key={ language.code }>
                { () => (
                  <button
                    key={ language.code }
                    type="button"
                    onClick={ () => handleChangeLanguage(language.code) }
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    role="menuitem"
                  >
                    <img
                      className="h-8 w-8 mr-8"
                      src={ language.icon }
                      alt={ language.name }
                    />
                  </button>
                ) }
              </Menu.Item>
            )) }
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default LanguageSwitcher;
