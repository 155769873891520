import React from 'react';
import { useTranslation } from 'react-i18next';
import CookieConsent from 'react-cookie-consent';

const CookieConsentBanner: React.FC = () => {
  const { t } = useTranslation();

  return (
    <CookieConsent
      location="bottom"
      buttonText={ t('cookieConsent.buttonText') }
      cookieName="ise-advertising"
      style={ {
        background: 'rgb(88 28 135)',
        minHeight: '5em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      } }
      buttonStyle={ { color: '#000000', backgroundColor: '#eab308', fontSize: '13px' } }
      expires={ 180 }
    >
      <div className="mx-auto p-4">
        <p className="text-white">
          { t('cookieConsent.content') }
        </p>
      </div>
    </CookieConsent>
  );
};

export default CookieConsentBanner;
