import React from 'react';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

import DigitalMarketing from '../../assets/digital-marketing-definition.webp';

const About: React.FC = () => {
  const { t } = useTranslation();
  const sanitizedTextHTML = DOMPurify.sanitize(t('sections.aboutUs.text'));

  return (
    <div className="bg-white py-20 min-h-screen flex items-center">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="md:w-1/2 mb-8 md:mb-0">
            <img
              src={ DigitalMarketing }
              alt={ t('sections.aboutUs.title') }
              className="w-full h-auto rounded-md"
            />
          </div>
          <div className="md:w-1/2 text-justify">
            <h2 className="text-3xl font-semibold mb-4">{ t('sections.aboutUs.title') }</h2>
            <div dangerouslySetInnerHTML={ { __html: sanitizedTextHTML } } />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
