import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { toast } from 'react-toastify';
import emailjs from '@emailjs/browser';

type FormData = {
  name: string
  email: string
  message: string
}

const ContactForm: React.FC = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState
  } = useForm<FormData>();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [reCaptchaToken, setReCaptchaToken] = useState<string>();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha('contactFormSubmit');
    setReCaptchaToken(token);
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const onSubmit: SubmitHandler<FormData> = async (formData: FormData) => {
    try {
      const data = {
        from_name: formData.name,
        replay_to: formData.email,
        message: formData.message,
        'g-recaptcha-response': reCaptchaToken,
      };

      const serviceId = process.env.REACT_APP_SERVICE_ID;
      const templateId = process.env.REACT_APP_TEMPLATE_ID;
      const publicKey = process.env.REACT_APP_PUBLIC_KEY;

      emailjs
        .send(serviceId!, templateId!, data, publicKey)
        .then((response) => {
          console.log(response);
          toast.success(t('contactForm.submittedSuccessfully'));
        })
        .catch((error) => {
          toast.error(t('contactForm.submittingError'));
          console.log(error);
        }).finally(() => reset());
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <form onSubmit={ handleSubmit(onSubmit) }>
      <div className="mb-4">
        <label htmlFor="name" className="block font-semibold">
          { t('contactForm.nameTitle') }
        </label>
        <input
          type="text"
          { ...register('name') }
          className="mt-1 p-2 w-full border rounded-md text-gray-600 focus:outline-none focus:ring focus:border-purple-800"
          placeholder={ t('contactForm.name') }
          required
        />
        { formState.errors.name && <p>{ formState.errors.name.message }</p> }
      </div>
      <div className="mb-4">
        <label htmlFor="email" className="block font-semibold">
          { t('contactForm.emailTitle') }
        </label>
        <input
          type="email"
          { ...register('email') }
          className="mt-1 p-2 w-full border rounded-md text-gray-600 focus:outline-none focus:ring focus:border-purple-800"
          placeholder={ t('contactForm.email') }
          required
        />
        { formState.errors.email && <p>{ formState.errors.email.message }</p> }
      </div>
      <div className="mb-4">
        <label htmlFor="message" className="block font-semibold">
          { t('contactForm.messageTitle') }
        </label>
        <textarea
          { ...register('message') }
          rows={ 4 }
          className="mt-1 p-2 w-full border rounded-md text-gray-600 focus:outline-none focus:ring focus:border-purple-800"
          placeholder={ t('contactForm.message') }
          required
        />
        { formState.errors.message && <p>{ formState.errors.message.message }</p> }
      </div>

      <button
        type="submit"
        className="bg-purple-800 p-2 rounded-md text-base font-medium text-white hover:bg-purple-700"
        disabled={ formState.isSubmitting }
      >
        { t('contactForm.button') }
      </button>
    </form>
  );
};

export default ContactForm;
