import React from 'react';
import { useTranslation } from 'react-i18next';

import Video from '../../assets/backgrounds/home-section.webm';
import BackgroundVideo from '../BackgroundVideo';

const Home: React.FC = () => {
  const { t } = useTranslation();

  const textStrokeStyles = {
    textShadow: '-1px -1px 0 #7e22ce, 1px -1px 0 #7e22ce, -1px 1px 0 #7e22ce, 1px 1px 0 #7e22ce',
  };

  return (
    <div className="py-20 min-h-screen flex items-center">
      <BackgroundVideo videoSource={ Video } />
      <div className="fixed w-full h-full top-0 left-0 bg-black bg-opacity-50 z-[-1]" />
      <div className="container mx-auto text-center text-white z-[1]">
        <h1 className="text-7xl font-bold mb-4" style={ textStrokeStyles }>{ t('sections.home.title') }</h1>
        <p className="text-4xl mb-8 text-stroke" style={ textStrokeStyles }>{ t('sections.home.description') }</p>
      </div>

    </div>
  );
};

export default Home;
