import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, Timestamp, updateDoc } from 'firebase/firestore';

import { db } from '../../Firebase';

const BlogEdit: React.FC = () => {
  const { id } = useParams<string>();

  const [title, setTitle] = useState<string>('');
  const [body, setBody] = useState<string>('');

  const blogRef = doc(db, 'blogs', id!);

  useEffect(() => {
    getDoc(blogRef).then((snapshot) => {
      const data = snapshot.data();
      setTitle(data!.title);
      setBody(data!.body);
    });
  }, [blogRef]);

  async function editBlog(e: any) {
    e.preventDefault();

    try {
      await updateDoc(blogRef, {
        title: title,
        body: body,
        last_Updated: Timestamp.fromDate(new Date()),
      });

      console.log('Data Successfully Updated. Blog ID:', blogRef.id);
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  }

  return (
    <div>

      <form onSubmit={ (event) => {
        editBlog(event);
      } }>
        <input type="text" placeholder="Title" value={ title }
               onChange={ (e) => {
                 setTitle(e.target.value);
               } } required />

        <textarea name="content"
                  value={ body }
                  placeholder="write yoyr content here"
                  rows={ 10 }
                  cols={ 150 }
                  onChange={ (e) => {
                    setBody(e.target.value);
                  } } required>
            </textarea>

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default BlogEdit;
