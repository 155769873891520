import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaFacebook, FaInstagram, FaRegEnvelope } from 'react-icons/fa';
import { MdOutlinePhone } from 'react-icons/md';

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8 min-h-[20rem]">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="mb-8 md:mb-0">
          <h2 className="text-2xl font-semibold mb-4">{ t('footer.aboutUs') }</h2>
          <p className="text-sm text-justify">
            { t('footer.aboutText') }
          </p>
        </div>
        <div className="mb-8 md:mb-0">
          <h2 className="text-2xl font-semibold mb-4">{ t('footer.contactUs') }</h2>
          <p className="text-sm">
            <FaRegEnvelope className="h-6 w-6 inline mr-1.5" aria-hidden="true" /> { t('footer.email') }: <a
            href="mailto:office@ise-advertising.com">office@ise-advertising.com</a>
            <br />
            <MdOutlinePhone className="h-6 w-6 inline mr-1.5" aria-hidden="true" /> { t('footer.phone') }: <a
            href="tel:+359882054242">+359 882 054 242</a>
          </p>
        </div>
        <div className="mb-8 md:mb-0">
          <h2 className="text-2xl font-semibold mb-4">{ t('footer.socialMedia') }</h2>
          <ul className="flex space-x-4">
            <li>
              <a href="https://www.facebook.com/iseadv"
                 className="text-white hover:text-gray-400"
                 target="_blank"
                 rel="noopener noreferrer"
              >
                <FaFacebook className="h-6 w-6 inline mr-1.5" size={ 24 } title="Facebook" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com"
                 className="text-white hover:text-gray-400"
                 target="_blank"
                 rel="noopener noreferrer"
              >
                <FaInstagram className="h-6 w-6 inline mr-1.5" size={ 24 } title="Instagram" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
