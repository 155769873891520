import React, { useCallback, useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';

import { db } from '../../Firebase';
import { Blog } from './interfaces';

const BlogView: React.FC = () => {
  const { id } = useParams<string>();

  const [blog, setBlog] = useState<Blog>();

  const blogRef = doc(db, 'blogs', id!);

  const fetchPost = useCallback(async () => {
    await getDoc(blogRef).then((snapshot) => {
      const data = snapshot.data() as Blog;
      setBlog(data);
    });
  }, [blogRef]);

  useEffect(() => {
    fetchPost();
  }, [fetchPost]);

  // async function getBlog() {
  //   const docRef = doc(db, "blogs", id!);
  //   const docSnap = await getDoc(docRef);
  //
  //   if (docSnap.exists()) {
  //     return docSnap.data();
  //   } else {
  //     return null;
  //   }
  // }

  return (
    <div>
      <div className="w-full max-w-2xl mx-auto">
        <h1 className="text-2xl">
          <span><b>Title :</b></span>
          <span>{ blog!.title }</span>
        </h1>
        <p><b>Body :</b></p>
        <p>{ blog!.body }</p>
      </div>
    </div>
  );
};

export default BlogView;
