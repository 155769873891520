import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconType } from 'react-icons';
import { AiOutlineGoogle } from 'react-icons/ai';
import { BsGraphUpArrow, BsMegaphone } from 'react-icons/bs';
import { PiGraph, PiMagnifyingGlassBold } from 'react-icons/pi';
import { MdOutlineMailOutline, MdOutlineWeb } from 'react-icons/md';
import { FaPaintBrush, FaPrint, FaRegCalendarAlt, FaShopify, FaWordpressSimple } from 'react-icons/fa';

interface Service {
  title: string;
  description: string;
  icon: IconType;
}

interface ServiceGroup {
  title: string;
  services: Service[];
}

const Services: React.FC = () => {
  const { t } = useTranslation();
  const serviceGroups: ServiceGroup[] = [
    {
      title: t('sections.services.digitalMarketing.title'),
      services: [
        {
          title: t('sections.services.digitalMarketing.socialMediaMarketing.title'),
          description: t('sections.services.digitalMarketing.socialMediaMarketing.description'),
          icon: PiGraph,
        },
        {
          title: t('sections.services.digitalMarketing.googleAds.title'),
          description: t('sections.services.digitalMarketing.googleAds.description'),
          icon: AiOutlineGoogle,
        },
        {
          title: t('sections.services.digitalMarketing.seo.title'),
          description: t('sections.services.digitalMarketing.seo.description'),
          icon: PiMagnifyingGlassBold
        },
        {
          title: t('sections.services.digitalMarketing.emailMarketing.title'),
          description: t('sections.services.digitalMarketing.emailMarketing.description'),
          icon: MdOutlineMailOutline
        },
        {
          title: t('sections.services.digitalMarketing.digitalStrategies.title'),
          description: t('sections.services.digitalMarketing.digitalStrategies.description'),
          icon: BsGraphUpArrow
        },
        {
          title: t('sections.services.digitalMarketing.creative.title'),
          description: t('sections.services.digitalMarketing.creative.description'),
          icon: FaPaintBrush
        },
      ],
    },
    {
      title: t('sections.services.webDevelopment.title'),
      services: [
        {
          title: t('sections.services.webDevelopment.wordpress.title'),
          description: t('sections.services.webDevelopment.wordpress.description'),
          icon: FaWordpressSimple
        },
        {
          title: t('sections.services.webDevelopment.shopify.title'),
          description: t('sections.services.webDevelopment.shopify.description'),
          icon: FaShopify
        },
        {
          title: t('sections.services.webDevelopment.customWeb.title'),
          description: t('sections.services.webDevelopment.customWeb.description'),
          icon: MdOutlineWeb
        },
      ]
    },
    {
      title: t('sections.services.advertisement.title'),
      services: [
        {
          title: t('sections.services.advertisement.printAdvertisement.title'),
          description: t('sections.services.advertisement.printAdvertisement.description'),
          icon: FaPrint
        },
        {
          title: t('sections.services.advertisement.influencerMarketing.title'),
          description: t('sections.services.advertisement.influencerMarketing.description'),
          icon: BsMegaphone
        },
        {
          title: t('sections.services.advertisement.mediaPlaning.title'),
          description: t('sections.services.advertisement.mediaPlaning.description'),
          icon: FaRegCalendarAlt
        },
      ]
    }
  ];

  function renderIcon(icon: IconType) {
    const Icon = icon;
    return (
      <Icon />
    );
  }

  return (
    <div className="bg-white py-20 min-h-screen flex items-center">
      <div className="container mx-auto px-4 flex flex-col items-center">
        <h2 className="text-3xl font-semibold mb-4">{ t('sections.services.title') }</h2>
        <div className="grid grid-cols-1">
          { serviceGroups.map((serviceGroup, groupIndex) => (
            <div key={ groupIndex } className="mb-6">
              <h2 className="text-2xl font-semibold mb-4">{ serviceGroup.title }</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                { serviceGroup.services.map((service, index) => (
                  <div
                    key={ index }
                    className="bg-white p-6 rounded-lg shadow-md flex items-center mb-2"
                  >
                    <div>
                      <h3 className="text-xl font-semibold mb-2 flex items-center">
                        <span className="mr-4">
                          { renderIcon(service.icon) }
                        </span>
                        { service.title }
                      </h3>
                      <p className="text-gray-600">{ service.description }</p>
                    </div>
                  </div>
                )) }
              </div>
            </div>
          )) }
        </div>
      </div>
    </div>
  );
};

export default Services;
