import React from 'react';
import { useTranslation } from 'react-i18next';

import CTO from '../../assets/team/asen_todorov.webp';
import CEO from '../../assets/team/hristina_todorova.webp';
import Designer from '../../assets/team/galiya_dimitrova.webp';
import Inspiration from '../../assets/team/toby.webp';
import WebDeveloper from '../../assets/team/stefan_ivanov.webp';

interface TeamMember {
  name: string;
  position: string;
  bio: string;
  imgSrc: string;
}

const Team: React.FC = () => {
  const { t } = useTranslation();

  const teamMembers: TeamMember[] = [
    {
      name: t('sections.team.members.ceo.name'),
      position: t('sections.team.members.ceo.position'),
      bio: t('sections.team.members.ceo.bio'),
      imgSrc: CEO,
    },
    {
      name: t('sections.team.members.cto.name'),
      position: t('sections.team.members.cto.position'),
      bio: t('sections.team.members.cto.bio'),
      imgSrc: CTO,
    },
    {
      name: t('sections.team.members.designer.name'),
      position: t('sections.team.members.designer.position'),
      bio: t('sections.team.members.designer.bio'),
      imgSrc: Designer,
    },
    {
      name: t('sections.team.members.developer.name'),
      position: t('sections.team.members.developer.position'),
      bio: t('sections.team.members.developer.bio'),
      imgSrc: WebDeveloper,
    },
    {
      name: t('sections.team.members.inspiration.name'),
      position: t('sections.team.members.inspiration.position'),
      bio: t('sections.team.members.inspiration.bio'),
      imgSrc: Inspiration,
    },
  ];

  return (
    <div className="bg-purple-700 text-white py-20 min-h-screen flex items-center">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-semibold mb-4">{ t('sections.team.title') }</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8">
          { teamMembers.map((member, index) => (
            <div
              key={ index }
              className="bg-white p-6 rounded-lg shadow-md"
            >
              <img
                src={ member.imgSrc }
                alt={ member.name }
                className="rounded-full mb-4 mx-auto h-36 w-36"
              />
              <h3 className="text-xl text-gray-600 font-semibold mb-2">{ member.name }</h3>
              <p className="text-gray-600 mb-2">{ member.position }</p>
              {/*<p className="text-sm text-gray-500">{ member.bio }</p>*/ }
            </div>
          )) }
        </div>
      </div>
    </div>
  );
};

export default Team;
