import React, { useCallback, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { collection, deleteDoc, doc, getDocs, limit, orderBy, query } from "firebase/firestore";

import { db } from "../../Firebase";
import { Blog } from "./interfaces";

const BlogList: React.FC = () => {
  const [blogs, setBlogs] = useState<Blog[]>([]);

  const Blogs = collection(db, 'blogs');
  const latest = query(Blogs, orderBy('published_on'), limit(100));

  const fetchPosts = useCallback(async () => {
    await getDocs(latest)
      .then((querySnapshot) => {
        const newData = querySnapshot.docs.map(doc => ({
          ...doc.data() as Blog,
        }));
        setBlogs(newData);
      });
  }, [latest]);

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  async function deleteBlog(id: string) {
    const blogRef = doc(db, 'blogs', id);
    deleteDoc(blogRef).then(() => {
      console.log("Document successfully deleted!");
    }).catch((err: any) => {
      console.error("Error removing document: ", err);
    });
  }

  return (
    <div>
      <h2 className="w-full text-center font-bold text-xl">All blogs List</h2>
      { blogs.map(blog => (
        <div key={ blog.id }>
          <p>Title : { blog.title }</p>
          <p>Body: { blog.body } </p>
          <Link to={ "/blog/" + blog.id }
                className="mr-2 bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-1 px-2 border border-indigo-500 rounded"
          >View
          </Link>
          <Link to={ "/blog/edit/" + blog.id }
                className="mr-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 border border-blue-500 rounded"
          >Edit
          </Link>
          <button
            onClick={ () => {
              deleteBlog(blog.id);
            } }
          >delete
          </button>
        </div>
      )) }
    </div>
  );
};

export default BlogList
