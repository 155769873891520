import React from 'react';
import { useTranslation } from 'react-i18next';

import GorillaGymLogo from '../../assets/customers/gorilla_gym.webp';
import RestaurantBears from '../../assets/customers/restaurant_bears.webp';
import PrefabHouse from '../../assets/customers/sglobyaem_dom.webp';
import UglyDuckling from '../../assets/customers/ugly_duckling.webp';
import Sante from '../../assets/customers/pharmacy_sante.webp';
import CrazyYoung from '../../assets/customers/ludi_mladi.webp';


interface Customer {
  name: string;
  logo: string;
  url: string;
}

const Customers: React.FC = () => {
  const { t } = useTranslation();

  const customers: Customer[] = [
    {
      name: t('sections.customers.list.gorillaGym'),
      logo: GorillaGymLogo,
      url: 'https://www.facebook.com/thegorillagym'
    },
    { name: t('sections.customers.list.prefabHouse'), logo: PrefabHouse, url: 'https://sglobyaemdom.com' },
    { name: t('sections.customers.list.crazyYoung'), logo: CrazyYoung, url: 'https://ludi-mladi.eu' },
    { name: t('sections.customers.list.uglyDuckling'), logo: UglyDuckling, url: 'https://www.groznotopate.bg' },
    { name: t('sections.customers.list.sante'), logo: Sante, url: 'https://www.facebook.com/AptekaSante' },
    { name: t('sections.customers.list.restaurantBears'), logo: RestaurantBears, url: 'https://mechkite.com' },
  ];

  return (
    <div className="bg-purple-700 text-white py-20 min-h-screen flex items-center">
      <div className="container mx-auto px-4 flex flex-col items-center">
        <h2 className="text-3xl font-semibold mb-4">{ t('sections.customers.title') }</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-6 gap-4">
          { customers.map((customer, index) => (
            <a key={ index } href={ customer.url } target="_blank" rel="noopener noreferrer">
              <div className="flex items-center justify-center h-full">
                <img
                  key={ index }
                  src={ customer.logo }
                  alt={ customer.name }
                  className="max-w-xs max-h-52 w-auto h-auto"
                />
              </div>
            </a>
          )) }
        </div>
      </div>
    </div>
  );
};

export default Customers;
