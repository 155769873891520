import { JSX } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Bounce, ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import {
  BlogCreate,
  BlogEdit,
  BlogList,
  BlogView,
  CookieConsentBanner,
  Footer,
  Main,
  MetaTag,
  Navbar,
} from './Components';

function App(): JSX.Element {
  return (
    <div className="App">
      <MetaTag />
      <BrowserRouter>
        <header className="App-header sticky top-0 z-50">
          <Navbar />
        </header>
        <main className="top-14">
          <ToastContainer
            position="top-right"
            autoClose={ 5000 }
            hideProgressBar={ false }
            newestOnTop={ false }
            closeOnClick
            rtl={ false }
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
            transition={ Bounce }
          />
          <Routes>
            <Route path="/" element={ <Main /> } />
            <Route path="/blogs" element={ <BlogList /> } />
            <Route path="/blogs/create/" element={ <BlogCreate /> } />
            <Route path="/blogs/:id" element={ <BlogView /> } />
            <Route path="/blogs/:id/edit" element={ <BlogEdit /> } />
            {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */ }
            {/*<Route path="*" element={ <NoMatch /> } />*/ }
          </Routes>
        </main>
      </BrowserRouter>

      <footer className="bg-purple-900 text-white">
        <Footer />
      </footer>
      <CookieConsentBanner />
    </div>
  );
}

export default App;
