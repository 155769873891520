import React from 'react';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { Disclosure } from '@headlessui/react';
import { FaBars, FaXmark } from 'react-icons/fa6';

import logo from '../assets/ise_logo_one_line.svg';
import LanguageSwitcher from './LanguageSwitcher';

const Navbar: React.FC = () => {
  // let onMain = true;
  const { t } = useTranslation();

  const navigationSections = [
    { name: t('menu.services'), href: 'services', active: false },
    { name: t('menu.team'), href: 'team', active: false },
    { name: t('menu.about'), href: 'about', active: false },
    { name: t('menu.customers'), href: 'customers', active: false },
    { name: t('menu.contactUs'), href: 'contact-us', active: false },
  ];

  // const navigationRouter = [
  //   { name: t('menu.blog'), href: '/blogs' },
  // ];

  function handleSetActive(to: any) {
    for (let item of navigationSections) {
      item.active = item.href === to;
    }
  }

  // function handleNavLinkClick() {
  //   onMain = false;
  //   console.log(onMain);
  // }

  return (
    <Disclosure as="nav" className="bg-white shadow">
      { ({ open }) => (
        <div>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-24 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/ }
                <Disclosure.Button
                  className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-500 hover:text-white hover:bg-purple-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-800">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  { open ? (
                    <FaXmark className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <FaBars className="block h-6 w-6" aria-hidden="true" />
                  ) }
                </Disclosure.Button>
              </div>
              <div
                className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <Link
                    key="Home"
                    to="home"
                    href={ '#home' }
                    spy={ true }
                    smooth={ true }
                    offset={ -70 }
                    duration={ 1500 }
                    delay={ 50 }
                  >
                    <img
                      className="h-8 w-auto"
                      src={ logo }
                      alt={ t('meta.companyName') }
                    />
                  </Link>
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    { navigationSections.map((item) => (
                      <Link
                        key={ item.name }
                        to={ item.href }
                        href={ `#${ item.href }` }
                        spy={ true }
                        smooth={ true }
                        offset={ -70 }
                        duration={ 1500 }
                        delay={ 50 }
                        onSetActive={ handleSetActive }
                        activeClass="bg-purple-800 text-white"
                        className={ `block rounded-md px-3 py-2 text-base font-medium link ${
                          !item.active
                            ? 'text-gray-500 hover:bg-purple-700 hover:text-white'
                            : ''
                        }` }
                      >
                        { item.name }
                      </Link>
                    )) }
                    {/*{ navigationRouter.map((item) => (*/ }
                    {/*  <NavLink*/ }
                    {/*    key={ item.name }*/ }
                    {/*    to={ item.href }*/ }
                    {/*    onClick={ handleNavLinkClick }*/ }
                    {/*    className={ ({ isActive, isPending }) =>*/ }
                    {/*      isActive*/ }
                    {/*        ? "block rounded-md px-3 py-2 text-base font-medium bg-purple-800 text-white"*/ }
                    {/*        : "block rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-purple-700 hover:text-white"*/ }
                    {/*    }*/ }
                    {/*  >*/ }
                    {/*    { item.name }*/ }
                    {/*  </NavLink>*/ }
                    {/*)) }*/ }
                  </div>
                </div>
              </div>
              <div
                className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                { <LanguageSwitcher /> }
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              { navigationSections.map((item) => (
                <Link
                  key={ item.name }
                  to={ item.href }
                  href={ `#${ item.href }` }
                  spy={ true }
                  smooth={ true }
                  offset={ -70 }
                  duration={ 1500 }
                  delay={ 50 }
                  onSetActive={ handleSetActive }
                  activeClass="bg-purple-800 text-white"
                  className={ `block rounded-md px-3 py-2 text-base font-medium link ${
                    !item.active
                      ? 'text-gray-500 hover:bg-purple-700 hover:text-white'
                      : ''
                  }` }
                >
                  { item.name }
                </Link>
              )) }
              {/*{ navigationRouter.map((item) => (*/ }
              {/*  <NavLink*/ }
              {/*    key={ item.name }*/ }
              {/*    to={ item.href }*/ }
              {/*    onClick={ handleNavLinkClick }*/ }
              {/*    className={ ({ isActive, isPending }) =>*/ }
              {/*      isActive*/ }
              {/*        ? "block rounded-md px-3 py-2 text-base font-medium bg-purple-800 text-white"*/ }
              {/*        : "block rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-purple-700 hover:text-white"*/ }
              {/*    }*/ }
              {/*  >*/ }
              {/*    { item.name }*/ }
              {/*  </NavLink>*/ }
              {/*)) }*/ }
            </div>
          </Disclosure.Panel>
        </div>
      ) }
    </Disclosure>
  );
};

export default Navbar;
