import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { bg, en } from './locales';

i18n.use(initReactI18next).init({
  resources: {
    bg: { translation: bg },
    en: { translation: en },
  },
  lng: 'bg', // Default language
  fallbackLng: 'bg', // Fallback language
  interpolation: {
    escapeValue: false, // React already handles this
  },
});

export default i18n;
