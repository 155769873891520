import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaFacebook, FaInstagram, FaRegEnvelope } from 'react-icons/fa';
import { MdOutlinePhone, MdShare } from 'react-icons/md';

import ContactForm from './ContactForm';

interface CompanyContact {
  phone: string;
  email: string;
  facebook: string;
  instagram: string;
}

const ContactUs: React.FC = () => {
  const { t } = useTranslation();

  const companyContact: CompanyContact = {
    phone: '+359 882 054 242',
    email: 'office@ise-advertising.com',
    facebook: 'https://www.facebook.com/iseadv',
    instagram: 'https://www.instagram.com',
  };

  return (
    <div className="bg-white py-20 min-h-screen flex items-center">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-semibold mb-4">{ t('sections.contacts.title') }</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="p-6 rounded-lg">
            <p className="mb-4">
              <MdOutlinePhone className="h-5 w-5 inline mr-1" /> { t('sections.contacts.phone') }:
              <a href={ 'tel:' + companyContact.phone }> { companyContact.phone } </a>
            </p>
            <p className="mb-4">
              <FaRegEnvelope className="h-5 w-5 inline mr-1" /> { t('sections.contacts.email') }:
              <a href={ 'mailto:' + companyContact.email }> { companyContact.email }</a>
            </p>
            <p className="mb-4">
              <MdShare className="h-5 w-5 inline mr-2" title="Social media" />
              { ' ' }
              { t('sections.contacts.socialMedia') }{ ' ' }
              <a href={ companyContact.facebook }
                 target="_blank"
                 rel="noopener noreferrer"
              ><FaFacebook className="h-5 w-5 inline mr-2" title="Facebook" color="#1877f2" /></a>
              { ' ' }
              <a href={ companyContact.instagram }
                 target="_blank"
                 rel="noopener noreferrer"
              ><FaInstagram className="h-5 w-5 inline mr-2" title="Instagram" color="#833ab4" /></a>
            </p>
          </div>

          <div className="p-6 rounded-lg">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
