import React from 'react';

interface Props {
  videoSource: string;
  videoType?: string;
}

const BackgroundVideo:React.FC<Props> = ({ videoSource, videoType = 'video/webm' }: Props) => {
  return (
    <div className="w-full h-full fixed top-0 left-0 z-[-10]">
      <video className="w-full h-full object-cover" autoPlay muted loop>
        <source src={ videoSource } type={ videoType } />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default BackgroundVideo;
