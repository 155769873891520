import React, { ReactNode } from 'react';

type Props = {
  id: string;
  childComponent: ReactNode;
};

const Section: React.FC<Props> = ({ id, childComponent }: Props) => {
  return (
    <section id={ id } className="h-auto min-h-screen">
      { childComponent }
    </section>
  );
};

export default Section;
