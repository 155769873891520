import menu from "./menu.json";
import meta from "./meta.json";
import footer from "./footer.json";
import languageSwitcher from "./languageSwitcher.json";
import cookieConsent from "./cookieConsent.json";
import contactForm from "./contactForm.json";
import sections from "./sections";

const bg = {
  meta: meta,
  menu: menu,
  footer: footer,
  languageSwitcher: languageSwitcher,
  cookieConsent: cookieConsent,
  contactForm: contactForm,
  sections: sections
};

export default bg;
