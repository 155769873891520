import aboutUs from "./aboutUs.json";
import contacts from "./contacts.json";
import customers from "./customers.json";
import home from "./home.json";
import services from "./services.json";
import team from "./team.json";
import underConstruction from "./underConstruction.json";


const sections = {
  aboutUs: aboutUs,
  contacts: contacts,
  customers: customers,
  home: home,
  services: services,
  team: team,
  underConstruction: underConstruction,
};

export default sections;
