import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const MetaTag: React.FC = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // Update meta tags when the language changes
    document.title = t('meta.companyName');
    document.querySelector('meta[name="title"]')?.setAttribute('content', t('meta.title'));
    document.querySelector('meta[name="description"]')?.setAttribute('content', t('meta.description'));
    document.querySelectorAll('meta[name="owner"], meta[name="copyright"]').forEach((meta) => {
      meta.setAttribute('content', t('meta.companyName'));
    });
    document.documentElement.lang = i18n.language;
  }, [t, i18n.language]);

  return (<></>); // This component doesn't render anything to the DOM
};

export default MetaTag;
